<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-card outlined class="mt-2">
        <v-toolbar flat>
          <v-list-item class="pl-0 pr-0">
            <v-list-item-content>
              <v-list-item-title>{{ customer.full_name }}</v-list-item-title>
              <v-list-item-subtitle>{{ customer.email }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    depressed
                    color="accent"
                    @click="$refs.proposalForm.openForm()"
                    v-on="on"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Tour</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <template v-slot:extension>
            <v-tabs>
              <v-tab :to="{ name: 'module-drum-customers-profile' }">
                <v-icon left>mdi-account-details</v-icon>Basic Details
              </v-tab>
              <v-tab :to="{ name: 'module-drum-customers-profile-tours' }">
                <v-icon left>mdi-golf</v-icon>Tours
              </v-tab>
              <v-tab :to="{ name: 'module-drum-customers-profile-marketing' }">
                <v-icon left>mdi-bullhorn</v-icon>Marketing
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="pa-5">
          <router-view v-bind:customer="customer"></router-view>
        </div>
      </v-card>
    </v-container>
    <ProposalForm ref="proposalForm" :customer="customer" :isEditing="false" />
  </div>
</template>

<script>
import ProposalForm from "../components/ProposalForm";

export default {
  components: {
    ProposalForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Customers",
          disabled: false,
          exact: true,
          to: {
            name: "module-drum-customers",
            params: { id: this.$route.params.id },
          },
        },
        {
          text: "Profile",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" },
        { text: "Actions", value: "actions", align: "right" },
      ],
    };
  },

  computed: {
    customer() {
      return this.$store.state.drum.customers["customer"];
    },
  },
};
</script>
